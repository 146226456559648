import React from "react"
import Page from "../../components/page"
import pageStyles from "../../components/page.module.css"
import Swatches from "../../components/Swatches/Swatches"

export default function GuestbookSwatches({ location, data }) {
  return (
    <Page title={"Guest Book Swatches – PhotoTailors"} location={location}>
      <div className={pageStyles.section}>
        <Swatches category={"guestbook"}/>
      </div>
    </Page>
  )
}
