import React, { useState, useEffect, useCallback } from "react"
import mockupSwatches from "../../../constants/mockupSwatches.json"
import styles from "./swatches.module.css"

const Swatches = ({ category }) => {
  const [dataFromServer, setDataFromServer] = useState([])
  const [data, setData] = useState([])
  const [selectedTab, setSelectedTab] = useState([])
  const [swatchesToDisplay, setSwatchesToDisplay] = useState([])

  const loadDataFromServer = useCallback(async () => {
    const response = await fetch('https://shop.phototailors.com/api/public/swatches2');
    // const response = await fetch('http://localhost:4000/public/swatches2');
    const res = await response.json();
    setDataFromServer(res[category])
  }, [])

  useEffect(() => {
    loadDataFromServer();
  }, []);

  // ORDER AND DISPLAY SWATCHES ON TAB CHANGE
  useEffect(() => {
    const selectedTabData = data?.find(tab => tab.id === selectedTab.id)
    const sortedTabItems = selectedTabData?.items.sort((a, b) =>
      a.order > b.order ? 1 : -1
    )
    setSwatchesToDisplay(sortedTabItems)
  }, [selectedTab])

  useEffect(() => {
    if(dataFromServer.length) {
      const sortedTabs = dataFromServer.sort((a, b) =>
        a.order > b.order ? 1 : -1
      )
      setData(sortedTabs)
      setSelectedTab(sortedTabs[0])
      setSwatchesToDisplay(sortedTabs[0].items);
    }
  }, [dataFromServer])

  return (
    <div className={styles.main}>
      {/* TABS LIST */}
      <div className={styles.tabsList}>
        {data.map(tab => {
          return (
            <div
              id={tab.id}
              className={`${styles.tabItem} ${
                selectedTab.id === tab.id && styles.selected
              }`}
              onClick={() => {
                setSelectedTab(tab)
              }}
            >
              {tab.name}
            </div>
          )
        })}
      </div>
      {/* SWATCHES LIST */}
      <div className={styles.swatchesList}>
        {swatchesToDisplay?.map(swatch => {
          return (
            <div key={swatch.id} className={styles.swatchMain}>
              <div className={styles.swatchImage}>
                <img src={swatch.thumb} />
              </div>
              <div className={styles.swatchName}>{swatch.name}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Swatches
